import logo from "./assets/logo.png";
import img1 from "./assets/image1.png";
import img2 from "./assets/image2.png";
import img3 from "./assets/image3.png";
import img4 from "./assets/image4.png";
import img5 from "./assets/image5.png";
import img6 from "./assets/image6.png";
import img7 from "./assets/image7.png";
import img8 from "./assets/image8.png";
import img9 from "./assets/image9.png";
import img10 from "./assets/image10.png";
import { Table } from "react-bootstrap";
function App() {
  return (
    <div class="container">
      <div class="logo">
        <img src={logo} alt="logo" />
        <h1>5ire Tap Guide</h1>
      </div>
      <h3 class="version">Edition of 05.08.2024</h3>
      <h2 class="title my-3">Overview</h2>
      <p class="content">
        5ire is an engaging and addictive tap-to-earn game where you tap the
        screen to accumulate 5ire points and 5ire tokens. As you advance, you
        can unlock new levels, explore various elements, and boost your
        earnings.{" "}
      </p>
      <h2 className="title">How to play </h2>
      <div className="gap-3 d-md-flex align-items-start img_box">
        <p className="content">
          Tap on the 5ire logo, and start collecting 5ire points, all your
          earnings will be displayed on the main screen.
        </p>
        <div>
          <img src={img2} alt="guide-image" />
          <p className="content text-center">Upgrade 5ire points and earns </p>
        </div>
      </div>
      <p className="content">
        When you gather a certain number of 5ire points and reach the next
        ultimate levels, you will receive rewards that increase your energy
        limits.
      </p>
      <Table responsive>
        <thead>
          <tr>
            <th>Lvl</th>
            <th>Lvl Name</th>
            <th>Balance</th>
            <th>Energy</th>
            <th>Per Tap Income</th>
          </tr>
        </thead>
        <tbody>
          {[
            {
              lvl: 1,
              name: "Water",
              balance: "0",
              energy: 2000,
              perTapIncome: 5,
            },
            {
              lvl: 2,
              name: "Hydro",
              balance: "1M",
              energy: 2500,
              perTapIncome: 6,
            },
            {
              lvl: 3,
              name: "Fire",
              balance: "3M",
              energy: 3000,
              perTapIncome: 7,
            },
            {
              lvl: 4,
              name: "Inferno",
              balance: "8M",
              energy: 3500,
              perTapIncome: 8,
            },
            {
              lvl: 5,
              name: "Earth",
              balance: "15M",
              energy: 4000,
              perTapIncome: 10,
            },
            {
              lvl: 6,
              name: "Crust",
              balance: "24M",
              energy: 4500,
              perTapIncome: 12,
            },
            {
              lvl: 7,
              name: "Air",
              balance: "35M",
              energy: 5000,
              perTapIncome: 14,
            },
            {
              lvl: 8,
              name: "Atmosphere",
              balance: "50M",
              energy: 5500,
              perTapIncome: 16,
            },
            {
              lvl: 9,
              name: "Space",
              balance: "70M",
              energy: 6000,
              perTapIncome: 18,
            },
            {
              lvl: 10,
              name: "Universe",
              balance: "90M",
              energy: 6500,
              perTapIncome: 20,
            },
          ].map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.lvl}</td>
                <td>{item.name}</td>
                <td>{item.balance}</td>
                <td>{item.energy}</td>
                <td>{item.perTapIncome}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="gap-3 d-md-flex align-items-start justify-content-between mt-4">
        <img src={img3} alt="help-guide-image" />
        <img src={img1} alt="help-guide-image" />
      </div>
      <p className="content mt-3">
        Earning 5ire points is simple: tap on the screen to get 5 5ire points
        per tap. As you progress to the next level, the number of 5ire points
        earned per tap will increase
      </p>
      <div className="gap-3 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>Energy Booster</h3>
          <ul>
            <li>You have 6 boosters available per day.</li>
            <li>
              When you exhaust your tapping power and want to continue
              immediately, you can use Full Energy to bypass the auto-refilling
              time.
            </li>
            <li>
              After using boosters to update your energy, you must wait 1 hour
              before using them again.
            </li>
          </ul>
        </div>
        <img src={img4} alt="help-guide-image" />
      </div>
      <div className="gap-3 d-md-flex align-items-start justify-content-between mt-4">
        <div>
          <p className="content">
            <b>You can also get extra 5ire points in the earn section </b>
          </p>
          <p className="content">
            In the Everyday game, you can earn extra 5ire points from the daily
            reward section. The number of 5ire points you receive increases each
            day, and on the 11th day, the cycle resets.
          </p>
        </div>
        <img src={img6} alt="help-guide-image" />
      </div>
      <p className="content mt-4">
        <b>Note:</b>
        Your progress will be reset, and you'll have to start collecting 5ire
        points from scratch if you miss even one day.
      </p>
      <div className="gap-3 d-flex align-items-start justify-content-between mt-4">
        <img src={img8} alt="help-guide-image" />
        <img src={img10} alt="help-guide-image" />
      </div>
      <div className="gap-3 mt-4 d-md-flex align-items-start justify-content-between">
        <div>
          <h3>Referral program</h3>
          <p className="content me-3">
            In the "Friends" section, you can send an invitation link. When your
            friend clicks on it, their name will appear at the bottom of your
            friend's list.
          </p>
        </div>
        <img src={img9} alt="help-guide-image" />
      </div>
      <h3 className="mt-4">5ire token as a reward</h3>
      <p className="content">
        You can earn 5ire tokens as a reward when you reach the ultimate level.
      </p>
      <Table responsive>
        <thead>
          <tr>
            <th>Level No.</th>
            <th>Level Name</th>
            <th>Level 5ire Token Earn</th>
          </tr>
        </thead>
        <tbody>
          {[
            { level: 1, name: "Water", tokenEarn: 0 },
            { level: 2, name: "Hydro", tokenEarn: 0 },
            { level: 3, name: "Fire", tokenEarn: 0 },
            { level: 4, name: "Inferno", tokenEarn: 0 },
            { level: 5, name: "Earth", tokenEarn: 0 },
            { level: 6, name: "Crust", tokenEarn: 1 },
            { level: 7, name: "Air", tokenEarn: 1 },
            { level: 8, name: "Atmosphere", tokenEarn: 2 },
            { level: 9, name: "Space", tokenEarn: 2 },
            { level: 10, name: "Universe", tokenEarn: 3 },
          ].map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.level}</td>
                <td>{item.name}</td>
                <td>{item.tokenEarn}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-md-flex align-items-start justify-content-between">
        <p className="me-3 content">
          You can collect your 5ire token in your wallet
        </p>
        <img src={img5} alt="help-guide-image" />
      </div>
      <div className="gap-3 d-md-flex align-items-start justify-content-between mt-4">
        <div>
          <h3>Staking</h3>
          <ul>
            <li>
              <strong>If you stake between 100k-200k 5ire tokens:</strong>
              <ul>
                <li>
                  Staking period of 30 days: You'll receive 100,000 5ire points
                  to claim.
                </li>
                <li>
                  Staking period of 90 days: You'll receive 150,000 5ire points
                  to claim.
                </li>
                <li>
                  Staking period of 180 days: You'll receive 200,000 5ire points
                  to claim.
                </li>
                <li>
                  Staking period of 365 days: You'll receive 500,000 5ire points
                  to claim.
                </li>
              </ul>
            </li>
            <li>
              <strong>If you have staked 200k-500k 5ire tokens:</strong>
              <ul>
                <li>
                  Staking period of 30 days: You'll get 200,000 5ire points to
                  claim.
                </li>
                <li>
                  Staking period of 90 days: You'll get 300,000 5ire points to
                  claim.
                </li>
                <li>
                  Staking period of 180 days: You'll get 400,000 5ire points to
                  claim.
                </li>
                <li>
                  Staking period of 365 days: You'll get 600,000 5ire points to
                  claim.
                </li>
              </ul>
            </li>
            <li>
              <strong>If you have staked 500k-1m 5ire tokens:</strong>
              <ul>
                <li>
                  Staking period of 30 days: You'll get 500,000 5ire points to
                  claim.
                </li>
                <li>
                  Staking period of 90 days: You'll get 1,000,000 5ire points to
                  claim.
                </li>
                <li>
                  Staking period of 180 days: You'll get 1,200,000 5ire points
                  to claim.
                </li>
                <li>
                  Staking period of 365 days: You'll get 1,250,000 5ire points
                  to claim.
                </li>
              </ul>
            </li>
            <li>
              <strong>If you have staked more than 1M 5ire tokens:</strong>
              <ul>
                <li>
                  Staking period of 30 days: You'll get 800,000 5ire points to
                  claim.
                </li>
                <li>
                  Staking period of 90 days: You'll get 1,200,000 5ire points to
                  claim.
                </li>
                <li>
                  Staking period of 180 days: You'll get 1,300,000 5ire points
                  to claim.
                </li>
                <li>
                  Staking period of 365 days: You'll get 2,000,000 5ire points
                  to claim.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <img src={img7} alt="help-guide-image" />
      </div>
    </div>
  );
}

export default App;
